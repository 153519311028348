@font-face {
	font-family: titilliumRegular;
	src: url(./fonts/Titillium_Web/TitilliumWeb-Regular.ttf);
}
@font-face {
	font-family: titilliumBlack;
	src: url(./fonts/Titillium_Web/TitilliumWeb-Black.ttf);
}
@font-face {
	font-family: titilliumLight;
	src: url(./fonts/Titillium_Web/TitilliumWeb-Light.ttf);
}

@font-face {
	font-family: titilliumExtraLight;
	src: url(./fonts/Titillium_Web/TitilliumWeb-ExtraLight.ttf);
}
html {
	scroll-behavior: smooth;
}

.home-search-bar {
	background-color: indianred;
}

.background-fancy {
	background: linear-gradient(-45deg, #ffe7e0, #ffdbe9, #eefcfb, #d9f8f1);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

.shadow {
	box-shadow: 0 1px 1px rgb(0 0 0 / 11%), 0 2px 2px rgb(0 0 0 / 11%), 0 4px 4px rgb(0 0 0 / 11%), 0 8px 8px rgb(0 0 0 / 11%), 0 16px 16px rgb(0 0 0 / 11%), 0 32px 32px rgb(0 0 0 / 11%);
}


.animated {
	animation-duration: 1s;
	animation-fill-mode: both;
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both
}


.fadeInUp {
	opacity: 0;
	display: block;
	animation-name: fadeInUp;
	animation-duration: 2s;
	-webkit-animation-name: fadeInUp;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
	from {
			transform: translate3d(0,40px,0)
	}

	to {
			transform: translate3d(0,0,0);
			opacity: 1
	}
}

@-webkit-keyframes fadeInUp {
	from {
			transform: translate3d(0,40px,0)
	}

	to {
			transform: translate3d(0,0,0);
			opacity: 1
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.pulse {
	animation: pulse 3s ease-in-out infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(175, 175, 175, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(212, 212, 212, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}